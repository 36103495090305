import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { TLanguageCodes } from "types"
import { fetchJson } from "utils/network"

export type TEmploymentTypeIds = 1 | 2 | 3 | 4

type TEmploymentType = {
  Bezeichnung: string
  CatAnstellungsartID: TEmploymentTypeIds
  BezeichnungML: Record<TLanguageCodes, string>
}

type TData = TEmploymentType[]
type THook = (options?: UseQueryOptions<TData>) => UseQueryResult<TData>

export const useEmploymentTypes: THook = (options) =>
  useQuery(
    `EMPLOYMENT_TYPES`,
    // @ts-ignore
    () => fetchJson<TData>(`catanstellungsarten`),
    // @ts-ignore
    options
  )
