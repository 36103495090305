import classNames from "classnames"
import Link from "next/link"
import { useRouter } from "next/router"
import posthog from "posthog-js"
import { useEffect, useState, ReactNode } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  Bricks as BricksIcon,
  Book as BookIcon,
  Certificates as CertifcatesIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  Employees as EmployeesIcon,
  Settings as SettingsIcon,
  Support as SupportIcon,
  UserManagement as UserManagementIcon,
} from "assets/icons"
import LogoStackedHorizontallyWithText from "assets/logos/StackedHorizontallyWithText"
import LogoSolo from "assets/logos/Standalone"
import Modal from "components/elements/Modal"
import TextInput from "components/forms/TextInput"
import { useCurrentUserContext } from "context/CurrentUserContext"
import { useSidebarContext } from "context/SidebarContext"
import { useCompanies, useSendSupportRequest } from "hooks/data"
import { EUserRoles } from "src/constants"
import { apiUrl } from "utils/network"

import PermissionGuard from "./PermissionGuard"
import ProfileDrawer from "./ProfileDrawer"

type TSubpageProps = {
  // name: TFuncKey<"navigation">
  name: string
  href: string
}

type TPageProps = {
  // name: TFuncKey<"navigation">
  name: string
  href: string
  Icon: typeof DashboardIcon
  view: EUserRoles[]
  subpages?: TSubpageProps[]
}

const pages: TPageProps[] = [
  {
    name: "certificates",
    href: "/certificates",
    view: [
      EUserRoles.USER,
      EUserRoles.HR,
      EUserRoles.SUPER_HR,
      EUserRoles.TECH_ADMIN,
    ],
    Icon: CertifcatesIcon,
  },
  {
    name: "employees",
    href: "/employees",
    view: [EUserRoles.HR, EUserRoles.SUPER_HR],
    Icon: EmployeesIcon,
  },
  {
    name: "admin.self",
    href: "/admin",
    view: [EUserRoles.HR, EUserRoles.SUPER_HR, EUserRoles.TECH_ADMIN],
    subpages: [
      {
        name: "admin.company",
        href: "/admin/company",
      },
      {
        name: "admin.settings",
        href: "/admin/settings",
      },
      {
        name: "admin.departments",
        href: "/admin/departments",
      },
      {
        name: "admin.functions",
        href: "/admin/functions",
      },
      {
        name: "admin.tasks",
        href: "/admin/tasks",
      },
      {
        name: "admin.templates",
        href: "/admin/templates",
      },
      {
        name: "admin.imports",
        href: "/admin/imports",
      },
    ],
    Icon: SettingsIcon,
  },
  {
    name: "textComponents.self",
    href: "/text-components",
    view: [EUserRoles.HR, EUserRoles.SUPER_HR],
    subpages: [
      {
        name: "textComponents.components",
        href: "/text-components/components",
      },
      {
        name: "textComponents.variables",
        href: "/text-components/variables",
      },
      {
        name: "textComponents.evaluations",
        href: "/text-components/evaluations",
      },
      {
        name: "textComponents.otherFormulas",
        href: "/text-components/other-formulas",
      },
    ],
    Icon: BricksIcon,
  },
  {
    name: "userManagement",
    href: "/user-management",
    view: [EUserRoles.HR, EUserRoles.SUPER_HR, EUserRoles.TECH_ADMIN],
    Icon: UserManagementIcon,
  },
]

type TContactFormValues = {
  firstName: string
  lastName: string
  email: string
  company: string
  phone: string
  message: string
}

function ContactFormModal() {
  const { t } = useTranslation("navigation")

  const { contactFormShown, hideContactForm } = useSidebarContext()
  const { currentUser } = useCurrentUserContext()

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const companiesQuery = useCompanies()
  const sendSupportRequest = useSendSupportRequest({
    onSuccess() {
      setShowSuccessMessage(true)
    },
  })

  const { register, trigger, getValues, formState, reset } =
    useForm<TContactFormValues>({
      mode: "onBlur",
    })
  const { errors } = formState

  useEffect(() => {
    if (currentUser && companiesQuery.data) {
      reset({
        firstName: currentUser.Vorname,
        lastName: currentUser.Nachname,
        email: currentUser.Email,
        company: companiesQuery.data[0].Name,
      })
    }
  }, [currentUser, companiesQuery.data, reset])

  async function onSubmit() {
    await trigger()

    if (Object.values(errors).length > 0) return

    sendSupportRequest.mutate(getValues())
  }

  return (
    <Modal
      show={contactFormShown}
      onClose={hideContactForm}
      onConfirmButtonClick={onSubmit}
      title={t("contactForm.title")}
      subtitle={t("contactForm.subtitle")}
      cancelButtonText={showSuccessMessage ? t("contactForm.close") : undefined}
      confirmButtonText={t("contactForm.send")}
      confirmButtonProps={{
        disabled: showSuccessMessage || sendSupportRequest.isLoading,
      }}
      width="2xl"
    >
      <div className="mt-10 grid grid-cols-2 gap-6">
        <TextInput
          id="firstName"
          disabled
          label={t("contactForm.firstName")}
          {...{ register }}
        />
        <TextInput
          id="lastName"
          disabled
          label={t("contactForm.lastName")}
          {...{ register }}
        />
        <TextInput
          id="email"
          disabled
          label={t("contactForm.email")}
          {...{ register }}
        />
        <TextInput
          id="company"
          disabled
          label={t("contactForm.companyName")}
          {...{ register }}
        />
      </div>

      {!showSuccessMessage && (
        <>
          <div className="mt-6">
            <TextInput
              id="message"
              label={t("contactForm.message")}
              multiline
              defaultValue={getValues("message")}
              {...{
                register,
                registerOptions: {
                  required: true,
                },
              }}
            />
          </div>

          <div className="mt-6 grid grid-cols-2 gap-6">
            <TextInput
              id="phone"
              label={t("contactForm.phone")}
              {...{ register }}
            />
          </div>
        </>
      )}

      {showSuccessMessage && <p>{t("contactForm.requestSent")}</p>}
    </Modal>
  )
}

type TSubpagesProps = Required<Omit<TPageProps, "view">> & {
  onTitleClick: (expanded: boolean) => void
}

function Subpages(props: TSubpagesProps): JSX.Element {
  const { name, subpages, href, Icon, onTitleClick } = props

  const { sidebarExpanded, expandSidebar } = useSidebarContext()
  const { isDev } = useCurrentUserContext()
  const { t } = useTranslation("navigation")
  const router = useRouter()

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (!sidebarExpanded) {
      setExpanded(false)
    }
  }, [sidebarExpanded])

  return (
    <div className="space-y-1">
      <button
        type="button"
        onClick={() => {
          if (!sidebarExpanded) {
            expandSidebar()
          }
          const nextState = !expanded
          onTitleClick(nextState)
          setExpanded(nextState)
        }}
        className={classNames([
          "submenu",
          "flex items-center",
          !sidebarExpanded ? "justify-center" : null,
          "group w-full",
          "rounded-md bg-white",
          "py-2 pl-2",
          "text-sm text-black",
          "hover:bg-gray-100",
          router.route.startsWith(href) ? "bg-gray-100 font-semibold" : null,
        ])}
        aria-controls="sub-menu-1"
        aria-expanded="false"
      >
        <Icon className="iconL mr-4" />
        {sidebarExpanded && t(name)}
      </button>

      {expanded && (
        <div className="space-y-1" id="sub-menu-1">
          {subpages.map((subpageProps) => {
            const { href: hrefSubpage } = subpageProps

            if (hrefSubpage === "/admin/settings" && !isDev) return

            return (
              <Link href={hrefSubpage} key={subpageProps.name} passHref>
                <div
                  className={classNames([
                    "group",
                    "flex w-full",
                    "items-center",
                    "rounded-md py-2 pl-14",
                    "pr-2 text-sm leading-8",
                    "text-black",
                    "hover:cursor-pointer",
                    "hover:bg-gray-100",
                    router.route === hrefSubpage
                      ? "bg-gray-100 font-semibold"
                      : null,
                  ])}
                >
                  {t(subpageProps.name)}
                </div>
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}

type TNavigationSidebarProps = {
  onProfileSectionClick: () => void
}

function NavigationSidebar(props: TNavigationSidebarProps): JSX.Element {
  const { onProfileSectionClick } = props

  const router = useRouter()

  const { t } = useTranslation("navigation")

  const {
    sidebarExpanded,
    expandSidebar,
    collapseSidebar,
    contactFormShown,
    showContactForm,
  } = useSidebarContext()
  const { currentUser } = useCurrentUserContext()

  const [submenuShown, setSubmenuShown] = useState(false)

  const buttonBaseStyle = classNames([
    "group flex",
    "w-full items-center",
    !sidebarExpanded ? "justify-center" : null,
    "rounded-md bg-white",
    "py-2",
    "pl-2 text-sm",
    "text-black",
    "hover:bg-gray-100",
  ])

  return (
    <div
      className={classNames([
        "NavigationSidebar",
        "fixed z-[1]",
        "flex h-screen flex-grow",
        "flex-col",
        "border-r border-black",
        "bg-white pt-5",
        "pb-4",
        sidebarExpanded ? "w-64" : "w-20",
        submenuShown ? "overflow-y-auto" : null,
      ])}
    >
      {!submenuShown && (
        <button
          type="button"
          title="Expand/collapse sidebar"
          className={classNames([
            "absolute -right-4 bottom-20",
            "h-8 w-8",
            "rounded-full",
            "border",
            "border-black",
            "bg-white p-2 text-black",
          ])}
          onClick={sidebarExpanded ? collapseSidebar : expandSidebar}
        >
          <ChevronRightIcon
            className={`${sidebarExpanded ? "rotate-180 transform" : ""}`}
          />
        </button>
      )}

      {/* LOGO */}
      <Link
        href="/"
        className={classNames([
          "flex",
          !sidebarExpanded ? "justify-center" : null,
          "px-4",
        ])}
      >
        {sidebarExpanded ? (
          <LogoStackedHorizontallyWithText className="h-12 w-28" />
        ) : (
          <LogoSolo className="h-12 w-12" />
        )}
      </Link>

      {/* MENU */}
      <div className="mb-32 mt-12 flex flex-col">
        <nav className="flex-1 space-y-1 bg-white px-2" aria-label="Sidebar">
          {pages.map((pageProps) => {
            const { href, name, Icon, view } = pageProps

            if (pageProps.subpages) {
              return (
                <PermissionGuard key={name} view={view}>
                  <Subpages
                    subpages={pageProps.subpages}
                    {...pageProps}
                    onTitleClick={(expanded) => {
                      setSubmenuShown(expanded)
                    }}
                  />
                </PermissionGuard>
              )
            }

            return (
              <PermissionGuard key={name} view={view}>
                <Link {...{ href }}>
                  <button
                    type="button"
                    className={classNames([
                      router.route === href ? "bg-gray-100 font-semibold" : "",
                      buttonBaseStyle,
                    ])}
                  >
                    <Icon className="iconL mr-4 font-semibold" />
                    {sidebarExpanded && t(name)}
                  </button>
                </Link>
              </PermissionGuard>
            )
          })}

          {/* SUPPORT */}
          <button
            type="button"
            className={buttonBaseStyle}
            onClick={showContactForm}
          >
            <SupportIcon className="iconL mr-4" />
            {sidebarExpanded && t("support")}
          </button>

          {/* MANUAL */}
          <a
            href="https://wiki.skriba.ch/"
            target="blank"
            className={buttonBaseStyle}
            onClick={() => {
              void posthog.capture("Manual viewed")
            }}
          >
            <BookIcon className="iconL mr-4" />
            {sidebarExpanded && t("wiki")}
          </a>
        </nav>
      </div>
      <div
        className={`flex ${
          sidebarExpanded ? "" : "justify-center"
        } mt-auto px-4`}
        onClick={onProfileSectionClick}
        onKeyDown={({ code }) => {
          if (code === "Space" || code === "Enter") {
            onProfileSectionClick()
          }
        }}
        role="button"
        tabIndex={0}
      >
        {sidebarExpanded && (
          <div className="ml-4 grid">
            <div className="font-semibold">
              {currentUser?.Vorname} {currentUser?.Nachname}
            </div>
            <div className="text-sm text-gray-700">{t("viewProfile")}</div>
          </div>
        )}
        {currentUser?.ProfileFileId && (
          <img
            crossOrigin="use-credentials"
            src={`${apiUrl}file/getUserPicture?userId=${currentUser.BenutzerID}`}
            alt="Current user"
            className={classNames([
              "ml-auto h-12",
              "w-12",
              "rounded-full",
              "border border-gray-300 object-cover",
            ])}
          />
        )}

        {currentUser && !currentUser.ProfileFileId && (
          <div
            className={classNames([
              "ml-auto flex h-12",
              "w-12 items-center",
              "justify-center",
              "rounded-full",
              "border border-gray-300 bg-gray-100",
              "text-lg",
            ])}
          >
            {currentUser.Vorname.charAt(0)} {currentUser.Nachname.charAt(0)}
          </div>
        )}
      </div>

      {contactFormShown && <ContactFormModal />}
    </div>
  )
}

type TLayoutProps = {
  children: ReactNode
}

export default function WithMainNavigation({
  children,
}: TLayoutProps): JSX.Element {
  const { sidebarExpanded } = useSidebarContext()

  const [showProfileDrawer, setShowProfileDrawer] = useState(false)

  return (
    <div>
      <NavigationSidebar
        onProfileSectionClick={() => setShowProfileDrawer(true)}
      />
      <main
        className={classNames([
          "min-h-screen",
          sidebarExpanded ? "ml-64" : "ml-20",
        ])}
      >
        {children}
      </main>
      {showProfileDrawer && (
        <ProfileDrawer
          show={showProfileDrawer}
          onClose={() => setShowProfileDrawer(false)}
        />
      )}
    </div>
  )
}
