import { AxiosResponse } from "axios"
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "react-query"

import { IItem, TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { TLanguage } from ".."

export type TTask = {
  Bezeichnung: string
  BezeichnungML: TLanguage
  CatAbteilung: IItem | null
  CatFunktion: IItem
  CatAufgabeTemplateID: number
  IsInUse: boolean
  ModDate: string
  Sequenz: number
}

type TQueryParams = operations["Tasks_GetList"]["parameters"]["query"] &
  TODataParams

type TData = TTask[]
type TResponse = AxiosResponse<TData>
type THook = (
  params?: TQueryParams,
  options?: UseInfiniteQueryOptions<TResponse>
) => UseInfiniteQueryResult<TResponse>

type TGetTasksParams = {
  pageParam?: {
    recordsToSkip: number
  }
  queryKey: [string, TQueryParams]
}

function getTasksInfinite({
  pageParam = {
    recordsToSkip: 0,
  },
  queryKey,
}: TGetTasksParams) {
  const [, queryParams] = queryKey
  const { recordsToSkip } = pageParam

  return fetchJson<TData, true, TQueryParams>("tasks", {
    queryParams: {
      ...queryParams,
      $skip: recordsToSkip,
      $inlinecount: "allpages",
    },
    fullResponse: true,
  })
}

export const useTasksInfinite: THook = (params, options) =>
  // @ts-ignore
  useInfiniteQuery(["TASKS_INFINITE", params], getTasksInfinite, {
    getNextPageParam: (lastPage, allPages) => {
      const url = new URL(lastPage.config.url ?? "")
      const hasRequestedTotalCount = url.searchParams.has("$inlinecount")

      if (!hasRequestedTotalCount) {
        console.error(
          "useCertificatesForTableInfinite: The total count was not requested in the query. This is required for infinite queries."
        )
        return undefined
      }

      const recordsToSkip = allPages.reduce((accumulator, page) => {
        accumulator += page.data.length
        return accumulator
      }, 0)
      if (recordsToSkip >= Number(lastPage.headers.totalcount)) return undefined

      return { recordsToSkip }
    },
    ...options,
  })
