import { useInfiniteQuery } from "react-query"

import { IUser, TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { userKeys } from "./keys"

export type TUseUsersInfiniteQueryParams =
  operations["Benutzer_GetList"]["parameters"]["query"] & TODataParams

type GetFunctionParams = {
  pageParam?: {
    recordsToSkip: number
  }
  queryKey: [string, TUseUsersInfiniteQueryParams]
}

function getUsersInfinite({
  pageParam = {
    recordsToSkip: 0,
  },
  queryKey,
}: GetFunctionParams) {
  const [, queryParams] = queryKey
  const { recordsToSkip } = pageParam

  return fetchJson<IUser[], true, TUseUsersInfiniteQueryParams>("benutzer", {
    queryParams: {
      ...queryParams,
      $skip: recordsToSkip,
      $inlinecount: "allpages",
      includeAuthorizationInfo: false,
    },
    fullResponse: true,
  })
}

export const useUsersInfinite = (params: TUseUsersInfiniteQueryParams) => {
  const query = useInfiniteQuery({
    queryKey: userKeys.infinite(params),
    queryFn: getUsersInfinite,

    getNextPageParam: (lastPage, allPages) => {
      const url = new URL(lastPage.config.url ?? "")
      const hasRequestedTotalCount = url.searchParams.has("$inlinecount")

      if (!hasRequestedTotalCount) {
        console.error(
          "useCertificatesForTableInfinite: The total count was not requested in the query. This is required for infinite queries."
        )
        return undefined
      }

      const recordsToSkip = allPages.reduce((accumulator, page) => {
        accumulator += page.data.length
        return accumulator
      }, 0)

      if (recordsToSkip >= Number(lastPage.headers.totalcount)) {
        return undefined
      }
      return { recordsToSkip }
    },
  })

  return query
}
