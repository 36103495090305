import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { TLanguageCodes } from "types"
import { fetchJson } from "utils/network"

import { locationKeys } from "./keys"

export interface ILocation {
  Bezeichnung: string
  BezeichnungML: Record<TLanguageCodes, string> & {
    Code: string | null
  }
  CatStandortID: number
  IsDefault: boolean
  IsInUse: boolean
  ModDate: string
  Portrait: string
  PortraitML:
    | (Record<TLanguageCodes, string> & {
        Code: string | null
      })
    | null
}

type TData = ILocation[]
type THook = (options?: UseQueryOptions<TData>) => UseQueryResult<TData>

export const useLocations: THook = (options) =>
  // @ts-ignore
  useQuery(locationKeys.all, () => fetchJson<TData>(`catstandorte`), options)
