import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"
import { SetOptional } from "type-fest"

import { fetchJson } from "utils/network"

import { ILocation } from "./useLocations"

type TData = ILocation
type TError = unknown
type TPayload = {
  BezeichnungML: SetOptional<ILocation["BezeichnungML"], "Code">
  IsDefault: ILocation["IsDefault"]
  PortraitML?: Partial<ILocation["PortraitML"]>
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const createLocation = (payload: TPayload) =>
  fetchJson<TData>("catstandorte", {
    method: "POST",
    body: payload,
  })

export const useCreateLocation: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(createLocation, {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
