import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { fetchJson } from "utils/network"

import { wordTemplateKeys } from "./keys"

export type TWordTemplate = {
  ZeugnisTemplateID: number
  ModDate: string
  Name: string
  LocationAndDatePosition: number
  IsMarketingPlacementUnderTitle: boolean
  PrintCompanyNameInSignature: boolean
  VornameUndNameFett: boolean
  CatStandort: {
    ID: number
    DisplayName: string
    Code: null
  }[]
  Validation: {
    MissingStyles: unknown[]
    MissingFonts: unknown[]
    NotEmbeddedFonts: unknown[]
    IsValid: boolean
  }
}

type TData = TWordTemplate[]
type TError = unknown
type THook = (
  options?: UseQueryOptions<TData, TError>
) => UseQueryResult<TData, TError>

export const useWordTemplates: THook = (options) =>
  useQuery(
    wordTemplateKeys.all,
    // @ts-ignore
    () => fetchJson<TData>("zeugnistemplate"),
    // @ts-ignore
    options
  )
