import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { EGenders } from "src/constants"
import { TLanguageCodes } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { ECertificateTypes } from "./useCertificateTypes"

type TQueryParams = operations["CatBereiche_GetList"]["parameters"]["query"]

export enum EEvaluationCategoryCodes {
  PROFFESIONAL = "fach",
  MANAGEMENT = "fuehrung",
  SOCIAL = "social",
}

export enum EEvaluationCategoryIds {
  PROFFESIONAL = 1,
  MANAGEMENT = 2,
  SOCIAL = 3,
}

export interface IEvaluationStatement {
  Sequenz: number
  BeurteilungsOptionen: IEvaluationStatementVariation[]
  IsDeactivated: boolean
}

export enum ESatisfactionLevels {
  EXCEEDS = 1,
  FULFILLS = 2,
  JUST_FULFILLS = 3,
}

export interface IEvaluationStatementVariation {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  AlwaysInPresentTense: boolean
  CatBeurteilungsOptionID: number
  CatBeurteilungsLevel: {
    ID: ESatisfactionLevels
    DisplayName: string
  }
  IsDeactivated: boolean
  CatSatzTemplate: {
    ID: number
    DisplayName: string
    ShortName: string
  }
  ShortName: string
  Text: string
}

export interface IEvaluationSubcategory {
  Aktiv?: boolean
  CatKriteriumID: number
  Bezeichnung: string
  BezeichnungML: Record<TLanguageCodes, string>
  Aussagen: IEvaluationStatement[]
}

export interface IEvaluationCategory {
  Bezeichnung: string
  BezeichnungML: Record<TLanguageCodes, string>
  CatBereichID: EEvaluationCategoryIds
  Code: EEvaluationCategoryCodes
  Kriterien: IEvaluationSubcategory[]
}

type TData = IEvaluationCategory[]
type TParams = {
  gender: EGenders
  certificateType?: ECertificateTypes
  language?: TLanguageCodes
}
type THook = (
  params: TParams,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

function getCategories(params: TParams) {
  const { certificateType, language, gender } = params

  return certificateType && language
    ? fetchJson<TData, false, TQueryParams>(`catbereiche`, {
        queryParams: {
          renderZeugnisTypId: certificateType,
          renderGeschlechtId: gender as unknown as number,
          renderSpracheId: language,
        },
      })
    : Promise.reject(new Error("Missing params"))
}

export const useEvaluationCategories: THook = (params, options) =>
  // @ts-ignore
  useQuery(["EVALUATION_CATEGORIES", params], () => getCategories(params), {
    ...options,
    enabled: Boolean(params.certificateType && params.language),
  })
