/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Configuration, PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { IconProvider } from "@synopsisapp/symbiosis-ui"
import axios, { AxiosError } from "axios"
import * as _ from "lodash-es"
import { AppProps } from "next/app"
import posthog from "posthog-js"
import React, { ReactNode, useEffect, useState } from "react"
import { Toaster, toast } from "react-hot-toast"
import { QueryClient, QueryClientProvider, useIsFetching } from "react-query"
// @ts-ignore
import { ReactQueryDevtools } from "react-query/devtools"
// import { ToastContainer, } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css"

import { ConfirmDialogProvider } from "@/components/elements/ConfirmContext/ConfirmProvider"
import Wrapper from "@/components/layouts/Wrapper"
import WithMainNavigationLayout from "components/layouts/WithMainNavigation"
import { AppContextProvider } from "context/AppContext"
import { CurrentUserProvider } from "context/CurrentUserContext"
import { DownloadProvider } from "context/DownloadContext"
import { SidebarProvider } from "context/SidebarContext"
import { TSystemInfo, CURRENT_USER_KEY } from "hooks/data"
import { LOCAL_STORAGE_KEYS, EUserRoles } from "src/constants"
import { IUser } from "types"
import { operations } from "types/apiSchema"
import { fetchJson, isDevEnv, isTestEnv } from "utils/network"
import "public/symbiosis-assets/symbiosis-ui.css"

import "translations/i18n"

import "../styles/globals.scss"

const twentyFourHoursInMs = 1000 * 60 * 60 * 24

const msalConfig: Configuration = {
  auth: {
    clientId: "",
    authority: "",
    redirectUri: "/",
    postLogoutRedirectUri: "/public/login",
  },
}

const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY
const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST

if (!posthogKey || !posthogHost) {
  console.warn(
    "Posthog key or host is not set. Please set the NEXT_PUBLIC_POSTHOG_KEY and NEXT_PUBLIC_POSTHOG_HOST environment variables."
  )
}

if (typeof window !== "undefined" && !!posthogKey) {
  posthog.init(posthogKey, {
    api_host: posthogHost || "https://eu.i.posthog.com",
    person_profiles: "identified_only",
    loaded: (posthogInstance) => {
      if (process.env.NEXT_PUBLIC_SKRIBA_ENV === "non-production")
        posthogInstance.debug()
    },
  })
}

const handle401 = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_CERTIFICATES)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_EMPLOYEES)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_USERS)

  if (isTestEnv || isDevEnv) {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SKRIBA_LOCAL_DEV_TOKEN)
    axios.defaults.headers.common.Authorization = ""
  }

  if (typeof window !== "undefined") {
    const { origin, pathname } = window.location
    const newPath = pathname === "/" ? "/certificates" : pathname

    // transfer the query params to the login page
    sessionStorage.setItem("redirectPathAfterLogin", newPath)

    const loginUrl = new URL("/public/login", origin)

    window.location.href = loginUrl.href
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: twentyFourHoursInMs,
      retry(failureCount, error) {
        const { response } = error as AxiosError
        if (
          response?.status === 403 &&
          response?.statusText === "Forbidden"
        ) {
          return false
        }
        return failureCount < 3
      },
      onError(error) {
        if ((error as AxiosError).response?.status === 401) {
          handle401()
        }
      },
    },
  },
})

function LoadingIndicator() {
  const queriesInProgress = useIsFetching()

  useEffect(() => {
    if (queriesInProgress > 0) {
      document.body.style.cursor = "progress"
    } else {
      document.body.style.cursor = "auto"
    }
  }, [queriesInProgress])

  return null
}

function MyApp(props: AppProps): ReactNode {
  const {
    Component,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    pageProps,
    router,
  } = props

  const { route } = router
  const isPublicPage = route.startsWith("/public")

  const [isUser, setIsUser] = useState(false)
  const [isTechAdmin, setIsTechAdmin] = useState(false)
  const [isHr, setIsHr] = useState(false)
  const [isSuperHr, setIsSuperHr] = useState(false)
  const [authorized, setAuthorized] = useState(false)
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>(
    new PublicClientApplication(msalConfig)
  )
  const [systemInfo, setSystemInfo] = useState<TSystemInfo>()

  useEffect(() => {
    const jwtToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.SKRIBA_LOCAL_DEV_TOKEN
    )
    if (jwtToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
    }

    if ("serviceWorker" in navigator && isDevEnv) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/sw.js", { scope: "/" })
          .then((registration) => {
            console.log(
              "Service worker registration successful 😎 Scope: ",
              registration.scope
            )
          })
          .catch((error) => {
            console.log("ServiceWorker registration failed 😒", error)
          })
      })
    }
  }, [])

  useEffect(() => {
    console.log("🔥 x10")
    console.log(!!posthogHost && !!posthogKey ? "✅ 🦔 " : "❌ 🦔")
  }, [])

  useEffect(() => {
    console.log("Posthog instance", posthog)
    const handleRouteChange = () => posthog?.capture("$pageview")
    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router])

  useEffect(() => {
    if (isHr || isSuperHr || isTechAdmin) {
      setAuthorized(true)
    } else if (isUser) {
      if (route.startsWith("/certificates")) {
        setAuthorized(true)
      } else if (!isPublicPage) {
        void router.replace("/certificates")
      }
    }
    // TEMP:
    // The intention here is to split this role off completely.
    // Doing so requires some rearrangment on the server.
    // else if (isTechAdmin) {
    //   if (route.startsWith("/employees") || route.startsWith("/admin") || route.startsWith("/user-management")) {
    //     setAuthorized(true)
    //   } else if (!isPublicPage) {
    //     void router.replace("/employees")
    //   }
    // }
  }, [router, route, isPublicPage, isUser, isTechAdmin, isHr, isSuperHr])

  useEffect(() => {
    if (isPublicPage) {
      // @ts-ignore
      void fetchJson<TSystemInfo>("system").then((data) => {
        const { AzureAdAuthority, AzureAdClientId } = data

        setMsalInstance(
          new PublicClientApplication({
            ...msalConfig,
            auth: {
              ...msalConfig.auth,
              clientId: AzureAdClientId,
              authority: AzureAdAuthority,
            },
          })
        )
        setSystemInfo(data)
      })
    }

    if (!isPublicPage) {
      const fetchCurrentUserRoles = async () => {
        try {
          const { SysRoles } = await queryClient.fetchQuery(
            CURRENT_USER_KEY,
            () =>
              fetchJson<IUser>("benutzer/current", {
                queryParams: {
                  includeAuthorizationInfo: false,
                },
              }),
            { retry: false }
          )

          const roleIds = SysRoles.map(({ ID }) => ID)

          setIsUser(roleIds.length === 1)
          setIsHr(roleIds.length === 2 && roleIds.includes(EUserRoles.HR))
          setIsSuperHr(roleIds.length === 4)
          setIsTechAdmin(
            roleIds.length === 2 && roleIds.includes(EUserRoles.TECH_ADMIN)
          )
        } catch (error) {
          if (error instanceof AxiosError && error.response?.status === 401) {
            handle401()
          }
        }
      };

      void fetchCurrentUserRoles()
    }
  }, [isPublicPage])

  return (
    <QueryClientProvider client={queryClient}>
      <IconProvider>
        <ConfirmDialogProvider>
          <AppContextProvider>
            <LoadingIndicator />

            <MsalProvider instance={msalInstance}>
              <GoogleOAuthProvider clientId="975346567909-38afadpud1d3f5khv1pjd41d7gvtqgsj.apps.googleusercontent.com">
                {isPublicPage && (
                  <Component
                    {...{
                      systemInfo,
                      ...pageProps,
                    }}
                  />
                )}
                {!isPublicPage && authorized && (
                  <CurrentUserProvider>
                    <DownloadProvider>
                      <SidebarProvider>
                        <WithMainNavigationLayout>
                          <Wrapper>
                            <Component {...pageProps} />
                          </Wrapper>
                        </WithMainNavigationLayout>
                      </SidebarProvider>
                    </DownloadProvider>
                  </CurrentUserProvider>
                )}
                <ReactQueryDevtools initialIsOpen={false} />
              </GoogleOAuthProvider>
            </MsalProvider>

            {/* <ToastContainer
              pauseOnFocusLoss={ false }
              position="top-center"
              limit={2}
              autoClose={3000}
            /> */}

            <Toaster
              position="top-center"
              reverseOrder={false}
              gutter={6}
              containerClassName=""
              toastOptions={{
                className: "",
                success: {
                  duration: 1500,
                  className: "zzz",
                  iconTheme: {
                    // green-400 (from config)
                    primary: "hsl(112 64% 52%)",
                    secondary: "white",
                  },
                },
                error: {
                  duration: 2000,
                  iconTheme: {
                    // red-400 (default)
                    primary: "#f87171",
                    secondary: "white",
                  },
                },
                loading: {
                  iconTheme: {
                    // inner spinning element, blue-600 (from config)
                    primary: "hsl(220 99% 54%)",
                    // ring, blue-200 (from config)
                    secondary: "hsl(213 97% 87%)",
                  },
                },
              }}
            />
          </AppContextProvider>
        </ConfirmDialogProvider>
      </IconProvider>
    </QueryClientProvider>
  )
}

export default MyApp
