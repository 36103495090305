import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { PartialDeep } from "type-fest"

import { LOCAL_STORAGE_KEYS } from "src/constants"
import { paths } from "types/apiSchema"

type TQueryParamsDefault = Record<string, string | number | boolean | undefined>

export type TFetchJsonOptions<TData, TQueryParams> = AxiosRequestConfig<
  PartialDeep<TData>
> & {
  body?: PartialDeep<TData>
  queryParams?: TQueryParams
  fullResponse?: boolean
}

function getSkribaBackendUrlCookie() {
  const cookie = document.cookie.match(/SKRIBA_BACKEND_URL=([^;]+)/)?.[1]

  const fallbackBackendUrl = process.env.NEXT_PUBLIC_API_URL

  return decodeURIComponent(fallbackBackendUrl ?? cookie ?? "")
}

export const apiUrl =
  typeof window === "undefined" ? "" : getSkribaBackendUrlCookie()
export const isDevEnv = process.env.NODE_ENV === "development"
export const isTestEnv = process.env.NEXT_PUBLIC_APP_ENV === "test"

function createQueryString<TQueryParams>(initParams?: TQueryParams) {
  if (!initParams || Object.keys(initParams).length === 0) return ""

  const params = Object.fromEntries(
    Object.entries(initParams)
      .filter(([, value]) =>
        value === 0 || value === false ? true : Boolean(value)
      )
      .map(([key, value]) => [key, String(value)])
  )
  const query = new URLSearchParams(params).toString()

  return `?${query}`
}

// This is a helper recursive type which ensures that all path segments
// become lowercased in a path containing literal types.
// Example: `AAA/${number}/BBB` becomes `aaa/{number}/bbb`.
// type LowerCasePath<T extends string, Delimeter extends string = "/"> = string extends T
//   ? never
//   : T extends `${infer Segment}${Delimeter}${infer Rest}`
//   ? `${Lowercase<Segment>}${Delimeter}${LowerCasePath<Rest, Delimeter>}`
//   : Lowercase<T>

type TFetchJsonReturn<TData, TFullResponse> = TFullResponse extends true
  ? AxiosResponse<TData & { Message?: string }>
  : TData & { Message?: string }

type THeaders = {
  "Content-Type": string
  Authorization?: string
}

export type ApiUrl = keyof paths extends `/api/${infer Path}`
  ? Lowercase<Path>
  : never

export async function fetchJson<
  TData,
  TFullResponse = false,
  TQueryParams = TQueryParamsDefault
>(
  url: ApiUrl,
  options: TFetchJsonOptions<TData, TQueryParams>
): Promise<TFetchJsonReturn<TData, TFullResponse>> {
  const headers: THeaders = {
    // @ts-ignore
    "Content-Type": "application/json",
    ...options?.headers,
  }
  const response = await axios(
    `${apiUrl}${url}${createQueryString<TQueryParams>(options?.queryParams)}`,
    {
      method: options?.method || "GET",
      withCredentials: true,
      data:
        options?.method === "POST" || options?.method === "PUT"
          ? options?.body
          : undefined,
      headers: headers,
      ...options,
      // fullResponse: options?.fullResponse
    }
  )
  // console.log( apiUrl, url, createQueryString(options?.queryParams) )

  if (isDevEnv || isTestEnv) {
    const jwtToken = response.headers["x-jwt-token"] as string
    if (jwtToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
      localStorage.setItem(LOCAL_STORAGE_KEYS.SKRIBA_LOCAL_DEV_TOKEN, jwtToken)
    }
  }
  if (options?.fullResponse) {
    return response as TFetchJsonReturn<TData, TFullResponse>
  }

  return response.data as TFetchJsonReturn<TData, TFullResponse>
}

function getFileName(headers: Response["headers"]): string | undefined {
  const contentDispositionHeader = headers.get("content-disposition")
  const fileName = /filename=(.+)/.exec(contentDispositionHeader ?? "")?.[1]
  return fileName ? decodeURIComponent(fileName.replaceAll('"', "")) : undefined
}

type TFetchBlobOptions<TQueryParams> = {
  method?: "POST"
  body?: FormData
  queryParams?: TQueryParams
}

type TFetchBlobReturn<TData> = TData extends Record<string, unknown> | string
  ? Promise<TData>
  : Promise<{
      blob: Blob
      fileName?: string
    }>

export async function fetchBlob<TData, TQueryParams = TQueryParamsDefault>(
  url: keyof paths extends `/api/${infer Path}` ? Lowercase<Path> : never,
  options: TFetchBlobOptions<TQueryParams> = {}
  // @ts-expect-error Promise<> isn't needed here
  // as it's already defined within the return type.
): TFetchBlobReturn<TData> {
  const response = await fetch(
    `${apiUrl}${url}${createQueryString(options.queryParams)}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: axios.defaults.headers.common.Authorization as string,
      },
      ...options,
    }
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  if (options.method === "POST") {
    const json = (await response.json()) as TFetchBlobReturn<TData>

    return json
  }

  const blob = await response.blob()

  // @ts-ignore
  return {
    blob,
    fileName: getFileName(response.headers),
  }
}
